
import { defineComponent, ref } from 'vue'
import TopBar from '@/components/TopBar.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'Global',
  components: {
    TopBar,
    Footer,
  },
  setup(){
    const menuTag = ref(1)
    const ind = ref(0)
    const baseUrl = ref('https://www.otimes.com/otimes_zw/img/')
    const pictureList = ref([
      [
        'plastic-pic-1.png',
        'plastic-pic-2.png',
        'plastic-pic-3.png',
        'plastic-pic-4.png',
        'plastic-pic-5.png'
      ],
      [
        'engineering-pic-2.png',
        'engineering-pic-3.png'
      ],
      [
        'chemical-pic-1.png',
        'chemical-pic-2.png'
      ],
      [
        'accessories-pic-1.png',
        'accessories-pic-3.png',
        'accessories-pic-4.png',
        'accessories-pic-5.png'
      ],
      [
        'steel-pic-1.png',
        'steel-pic-2.png',
        'steel-pic-3.png',
        'steel-pic-4.png',
        'steel-pic-5.png'
      ]
    ])
    const prev = () => {
      ind.value -= 1
      if (ind.value == -1) {
        ind.value = pictureList.value[menuTag.value - 1].length - 1
      }
    }
    const next = () => {
      ind.value += 1
      if (ind.value == pictureList.value[menuTag.value - 1].length) {
        ind.value = 0
      }
    }
    return{
      menuTag,
      ind,
      baseUrl,
      pictureList,
      prev,
      next,
    }
  }
});
